import React, { useEffect, useState } from "react";
import { colors, Blink, BTypography, PathProgress, SortableList, UserAvatar, FilterTextField, FormAutocompleteField, ProgramAvatar, NormalButton, ProgramBadge, PopTip } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { ClickAwayListener, Grid, List, ListItemButton, Paper, Popper } from "@mui/material";
import { downloadRequest } from "bild-data/core/comm";
import UnassignUserProgramDialog from "modules/management/manageUsers/components/unassignUserProgramDialog";
import UpdateUserProgramShepherdDialog from "modules/management/manageUsers/components/updateUserProgramShepherdDialog";
import RefreshUserProgramDialog from "modules/management/manageUsers/components/refreshUserProgramDialog";

const useStyles = makeStyles({
  wrapper: { padding: "1rem 1rem 2rem 1rem" },
  groupFilter: { paddingLeft: "0.5rem", minWidth: "15rem" },
  header: { padding: "1rem", backgroundColor: colors.white },
  body: { },
  overview: { borderBottom: `1px solid ${colors.black}` },
  learners: { padding: "1rem 0", paddingBottom: "4rem" },
  column: {
    borderRight: `1px solid ${colors.black}`,
    textAlign: "center",
    padding: "0.5rem",
    maxHeight: "20rem",
    overflowY: "auto",
    "&:last-child": { borderRight: "none" }
  },
  learner: {
    padding: "1rem",
    backgroundColor: colors.white,
    borderBottom: `0.1rem solid ${colors.lightGray}`
  },
  avatarName: { paddingLeft: "0.25rem", overflowWrap: "normal" },
  path: { paddingRight: "0.25rem" },
  programCol: {
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    overflow: "hidden"
  },
  subtitle: { paddingLeft: "0.25rem", colors: colors.bildBlue },
  link: { color: colors.lightBlue, textDecoration: "underline", "&:hover": { textDecoration: "none", color: colors.darkBlue } },
  button: { backgroundColor: colors.orange },
  popper: { zIndex: 100 }
});

export default function StaffOrganizationLearnersReview({ organization, orgGroups, learners, allUsers, updateShepherd, removeProgram, refreshProgram }) {
  const cls = useStyles();
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState("");
  const [groupFilter, setGroupFilter] = useState(null);
  const [groupFilterOptions, setGroupFilterOptions] = useState([]);

  const [popContext, setPopContext] = useState({});
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [confirmUserPath, setConfirmUserPath] = useState({});
  const [confirmShepherd, setConfirmShepherd] = useState({});
  const [confirmRefreshProgram, setConfirmRefreshProgram] = useState({});

  // Organization Groups Filter
  useEffect(()=>{
    if (orgGroups) {
      let ogs = [];
      // Hub Level
      for (let i=0; i<orgGroups.children.length; i++) {
        const hub = orgGroups.children[i];
        ogs.push({id: hub.id, name: hub.name, subtitle: orgGroups.level3Name });
        // Cluster Level
        for (let j=0; j<hub.children.length; j++) {
          const cluster = hub.children[j];
          ogs.push({id: cluster.id, name: cluster.name, subtitle: orgGroups.level2Name });
          // House Church Level
          for (let k=0; k<cluster.children.length; k++) {
            const houseChurch = cluster.children[k];
            ogs.push({id: houseChurch.id, name: houseChurch.name, subtitle: orgGroups.level1Name });
          }
        }
      }
      setGroupFilterOptions(ogs);
    }
  },[orgGroups]);

  useEffect(() => {
    // Find Group Filter in OrgGroups
    let groupUsers = [];
    if (groupFilter) {
      // Hub Level
      for (let i=0; i<orgGroups.children.length; i++) {
        const hub = orgGroups.children[i];
        let hubMatch = false;
        if (hub.id === groupFilter) {
          hubMatch = true;
        }
        if (hubMatch) { groupUsers = [...groupUsers, ...hub.users] }

        // Cluster Level
        for (let j=0; j<hub.children.length; j++) {
          const cluster = hub.children[j];
          let clusterMatch = false;
          if (cluster.id === groupFilter) {
            clusterMatch = true;
          }
          if (hubMatch || clusterMatch) { groupUsers = [...groupUsers, ...cluster.users] }
          // House Church Level
          for (let k=0; k<cluster.children.length; k++) {
            const houseChurch = cluster.children[k];
            let houseChurchMatch = false;
            if (houseChurch.id === groupFilter) {
              houseChurchMatch = true;
            }
            if (hubMatch || clusterMatch || houseChurchMatch) { groupUsers = [...groupUsers, ...houseChurch.users] }
          }
        }
      }
    }
    
    // map raw org data into list format
    const fData = learners
    .filter(l => {
      if (groupFilter) {
        // Compare user id to list of users in this group
        return (groupUsers.some(gu => gu.id === l.user.id));
      } else {
        return 1;
      }
    })
    .filter(l => {
      if (filter.length > 0) {
        return (
          l.user.name.toLowerCase().includes(filter.toLowerCase()) ||
          l.userPaths.some(up =>
            l.user.name.toLowerCase().includes(filter.toLowerCase())
            || l.userPaths.some(up => up.title.toLowerCase().includes(filter.toLowerCase()))
            || l.userPaths.some(up => up.subtitle && up.subtitle.toLowerCase().includes(filter.toLowerCase()))
          )
        );
      } else {
        return 1;
      }
    })
    .sort((a, b) => a.user.name.localeCompare(b.user.name))
    .map((x, i) => [
      x.userPaths
    ]);

    setItems(
      fData.flat().flat().filter(up => {
        // Filter Named Programs
        if (
          up.user.name.toLowerCase().includes(filter.toLowerCase()) ||
          up.title.toLowerCase().includes(filter.toLowerCase())
          || (up.subtitle && up.subtitle.toLowerCase().includes(filter.toLowerCase()))
        ) {
          return true;
        }
      }).map((x, i) => [
        [
          <NormalButton variant="no-style" component={Blink} dst="manage-user" user_id={x.user.id} sortval={x.user.name} key={i} >
            <UserAvatar size={40} src={x.user.avatarURL} name={x.user.name} srcSize="small" badgeSrc={x.user.badgeURL} />
          </NormalButton>
        ],
        [
          <NormalButton variant="no-style" component={Blink} dst="manage-user" user_id={x.shepherdUser.id} sortval={x.shepherdUser.name} key={i} >
            <UserAvatar size={40} src={x.shepherdUser.avatarURL} name={x.shepherdUser.name} srcSize="small" badgeSrc={x.shepherdUser.badgeURL} />
          </NormalButton>
        ],
        [<Grid key={i} sortval={x.completed ? "Completed" : (x.active && !x.completed ? "Active" : "Inactive")}>{x.completed ? "Completed" : (x.active && !x.completed ? "Active" : "Inactive")}</Grid>],
        [<Grid container key={i} alignItems="center" sortval={x.title} className={cls.programCol}>
          <Grid container item xs={10}>
            <ProgramAvatar
              userPathId={x.id}
              size={25}
              icon={"fas fa-badge-check"}
              color={x.programColor.webFrontendValue}
              name={x.title}
              subtitle={x.subtitle}
              nameVariant="body1"
              subtitleVariant="body1"
            />
          </Grid>
          <Grid item xs={2}>
            {x.completed && (
              <Grid container item xs={12} alignItems={"center"}>
                {x.completed && x.certificates && x.certificates.map((c, j) => (
                  <Grid item className={cls.certificate} key={j}>
                    <NormalButton
                      variant={"secondary"}
                      color={colors.white}
                      labelColor={x.programColor.webFrontendValue}
                      borderColor={x.programColor.webFrontendValue}
                      hoverLabelColor={colors.white}
                      hoverColor={x.programColor.webFrontendValue}
                      onClick={() => {
                        downloadRequest(c.downloadURL, c.name);
                      }}
                    >
                      {x.badges && JSON.parse(x.badges).map((b, i) => (
                        <ProgramBadge color={x.programColor.webFrontendValue} badgeURL={b.imageURL} completed={x.completed} key={i}/>
                      ))}
                      {!x.badges && (<i className="far fa-download" />)}
                    </NormalButton>
                  </Grid>
                ))}
              </Grid>
            )}
            {!x.completed && (<PathProgress
              totalCompletedCoreSteps={x.totalCompletedCoreSteps}
              totalInProgressCoreSteps={x.totalInProgressCoreSteps}
              totalCoreSteps={x.totalCoreSteps}
              totalCompletedSteps={x.totalCompletedSteps}
              totalInProgressSteps={x.totalInProgressSteps}
              totalSteps={x.totalSteps}
            />)}
          </Grid>
        </Grid>],
        [<Grid container justifyContent={"flex-end"} alignItems={"center"} key={i}>
          <Grid item>
            <PopTip text={x.completed ? "Completed Programs cannot be updated. Please contact support with any requests." : null}>
              <NormalButton
                variant="simple"
                onClick={e => {
                  onMenuClick(e, x);
                }}
                disabled={x.completed}
              >
                <i className={`far ${x.completed ? "fa-horizontal-rule" : "fa-ellipsis-h"}`} />
              </NormalButton>
            </PopTip>
          </Grid>
        </Grid>]
      ])
    );
  }, [learners, filter, groupFilter]);

  function onMenuClick(e, progData) {
    setPopContext(progData);
    setMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  return (
    <Grid container className={cls.wrapper}>
      <Grid container item xs={12} className={cls.header} justifyContent="space-between" alignItems="center">
        <Grid item>
          <BTypography variant="h4">{organization.organizationName} &mdash; Equip Programs</BTypography>
        </Grid>
        <Grid container item xs justifyContent="flex-end" alignItems="center">
          {orgGroups && orgGroups.children && orgGroups.children.length > 0 && (
            <Grid item className={cls.groupFilter}>
              <FormAutocompleteField placeholder={`${orgGroups.level1Name} Filter`} value={groupFilter} setValue={setGroupFilter} items={groupFilterOptions} variant="small" />
            </Grid>
          )}
          <Grid item>
            <FilterTextField
              value={filter}
              placeholder="Search"
              onChange={e => setFilter(e.target.value)}
              onClear={() => {
                setFilter("");
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.body}>
        <SortableList
          perPage={10}
          filter={filter}
          filterableCols={[0,1,2,3]}
          headers={["Name", "Shepherd", "Status", "Program", ""]}
          items={items}
          itemType={"Programs"}
          spacing={[2,2,2,5,1]}
          itemPadding={"0.25rem 0.25rem"}
          fixedHeight={"60vh"}
          emptyMessage={"No programs exist or match the current search criteria."}
        />
      </Grid>
      <Popper open={menuOpen} anchorEl={menuAnchorEl} className={cls.popper}>
        <ClickAwayListener onClickAway={closeMenu} mouseEvent="onMouseDown">
          <Paper>
            <List dense>
              <ListItemButton
                dense
                onClick={() => {
                  closeMenu();
                  setConfirmShepherd(popContext);
                }}
              >
                <i className="fal fa-clipboard-user fa-fw" /> &nbsp; Change Shepherd
              </ListItemButton>
              <ListItemButton
                dense
                onClick={() => {
                  closeMenu();
                  setConfirmUserPath(popContext);
                }}
              >
                <i className="fal fa-badge-check fa-fw" /> &nbsp; Unassign Program
              </ListItemButton>
              {popContext && !popContext.active && !popContext.completed && (
                <ListItemButton
                  dense
                  onClick={() => {
                    closeMenu();
                    setConfirmRefreshProgram(popContext);
                  }}
                >
                  <i className="fal fa-sync fa-fw" /> &nbsp; Reactivate Program
                </ListItemButton>
              )}
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
      <UnassignUserProgramDialog
        open={confirmUserPath.id ? true : false}
        onClose={() => {
          setConfirmUserPath({});
        }}
        confirmUserPath={confirmUserPath}
        removeProgram={removeProgram}
      />
      <UpdateUserProgramShepherdDialog
        open={confirmShepherd.id ? true : false}
        onClose={() => {
          setConfirmShepherd({});
        }}
        allUsers={allUsers}
        confirmShepherd={confirmShepherd}
        updateShepherd={updateShepherd}
      />
      <RefreshUserProgramDialog
        open={confirmRefreshProgram.id ? true : false}
        onClose={() => {
          setConfirmRefreshProgram({});
        }}
        confirmRefreshProgram={confirmRefreshProgram}
        refreshProgram={refreshProgram}
      />
    </Grid>
  );
}
