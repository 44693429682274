import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { managerData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import { toaster } from "presentational/toasts/toasts.js";
import ManageUser from "./manageUser.js";

function ManageUserLoader({}) {
  const { user_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [shepherds, setShepherds] = useState([]);
  const [orgGroups, setOrgGroups] = useState([]);
  const [activityData, setActivityData] = useState(null);
  const [shepherdingData, setShepherdingData] = useState({});

  useEffect(() => {
    setPageTitle("User Management");

    if (user_id) {
      function _setData(d) {
        setUser(d);
        setOrgGroups(d.primaryOrganizationGroups);
        setLoading(false);
      }

      managerData.getManagedUser(user_id, _setData, e => {
        setError(e.response.data.error);
      });
      managerData.getManagedUserCertificates(user_id, (d)=>{setCertificates(d.certificates)}, e => {
        setError(e.response.data.error);
      });
      managerData.getManagedUsers(
        x => {
          setShepherds(x.users);
        },
        e => {
          setError(e.response.data.error);
        }
      );
      managerData.getManagedUserRecentActivity(user_id, setActivityData);
      managerData.getManagedUserShepherding(user_id, setShepherdingData);
    }
  }, []);

  function updateUser(shepherd, paths) {
    let pathsToAdd = paths
      ? paths.map(x => {
          return { user: { id: user.id }, shepherdUser: { id: shepherd }, pathId: x };
        })
      : [];
    let data = {
      id: user.id,
      userPaths: pathsToAdd
    };
    managerData.updateManagedUser(user.id, data, x => {
      toaster.success(`Program${paths.length > 1 ? "s" : ""} Added!`);
      setUser(x);
    });
  }

  function updateShepherd(userId, userPathId, shepherdUserId) {
    let data = {
      id: userId,
      userPathsToUpdate: [{ id: userPathId, shepherdUser: { id: shepherdUserId } }]
    };
    managerData.updateManagedUser(userId, data, x => {
      toaster.success(`Program Shepherd Updated!`);
      setUser(x);
    });
  }

  function removeProgram(userId, userPathId) {
    let data = {
      id: userId,
      userPathsToRemove: [{ id: userPathId }]
    };
    managerData.updateManagedUser(userId, data, x => {
      toaster.success(`Program Unassigned`);
      setUser(x);
    });
  }

  if (loading || error) return <LoadingScreen error={error} />;
  return (
    <ManageUser
      user={user}
      certificates={certificates}
      shepherds={shepherds}
      orgGroups={orgGroups}
      updateUser={updateUser}
      activityData={activityData}
      shepherdingData={shepherdingData}
      updateShepherd={updateShepherd}
      removeProgram={removeProgram}
    />
  );
}

export default ManageUserLoader;
