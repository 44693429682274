import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import { makeStyles } from "@mui/styles";
import { BTypography, colors, NormalButton, UserAvatar } from "bild-ui";

const useStyles = makeStyles({
  dialogTitleHeader: props => ({
    background: props.pathColor,
  }),
  dialogTitle: {
    color: colors.white,
    fontWeight: "bold",
  },
  shepherdWrapper: {
    padding: "0 0 1rem 0"
  },
  fieldType: { fontWeight: "bold", minWidth: "8rem" },
  pathTitle: props => ({
    color: props.pathColor,
    lineHeight: "2rem",
    fontWeight: "bold",
    padding: "2rem 0 2rem 0",
    "& sup": {
      lineHeight: "1rem"
    }
  }),
  icon: {
    padding: "0 0.5rem 0 0.3rem"
  },
  confirmDialog: { padding: "1rem" },
  confirmTextWrapper: { padding: "2rem 0" },
  noteWrapper: { padding: "0 0 1rem 0"},
  confirmButton: { margin: "0.5rem 0", padding: "1rem 2rem" },
  status: { padding: "1rem 0rem 0rem 2.5rem" }
});

export default function RefreshUserProgramDialog({ open, onClose, confirmRefreshProgram, refreshProgram }) {
  const pathColor = confirmRefreshProgram && confirmRefreshProgram.programColor ? confirmRefreshProgram.programColor.webFrontendValue : colors.darkGray;
  const cls = useStyles({ pathColor });

  function _refreshProgram(userId, userPathId) {
    refreshProgram(userId, userPathId);
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      {confirmRefreshProgram && confirmRefreshProgram.id && (
        <>
          <DialogTitle className={cls.dialogTitleHeader}>
            <Grid container justifyContent={"space-between"} alignItems={"center"}>
              <Grid item>
                <BTypography variant="h5" className={cls.dialogTitle}>
                  <i className="fal fa-sync fa-fw" /> &nbsp; Refresh Program
                </BTypography>
              </Grid>
              <Grid item>
                <NormalButton
                  onClick={onClose}
                  variant="secondary"
                  color={colors.transparent}
                  labelColor={colors.white}
                  hoverColor={colors.darkGray}
                  hoverLabelColor={colors.white}
                >
                  <i className="far fa-times fa-2x" />
                </NormalButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container item xs={12} direction="column" className={cls.confirmDialog} justifyContent="center" alignItems="center">
              <Grid container item xs={12} justifyContent={"center"} alignItems={"center"}>
                <Grid container item className={cls.confirmTextWrapper} justifyContent={"center"} alignItems={"center"}>
                  <Grid container item justifyContent="flex-start" alignItems="center">
                    <Grid item>
                      <BTypography variant="h5" className={cls.fieldType}>Learner: &nbsp;</BTypography>
                    </Grid>
                    <Grid item xs>
                      <BTypography variant="h5">
                        <UserAvatar size={30} src={confirmRefreshProgram.user.avatarURL} name={confirmRefreshProgram.user.name} nameVariant="h5" badgeSrc={confirmRefreshProgram.user.badgeURL} />
                      </BTypography>
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="flex-start" alignItems="center">
                    <Grid item>
                      <BTypography variant="h5" className={cls.fieldType}>Program: &nbsp;</BTypography>
                    </Grid>
                    <Grid item xs className={cls.pathTitle}>
                      <BTypography variant="h5">
                        <i className={`fas ${confirmRefreshProgram.icon ? confirmRefreshProgram.icon : "fa-badge-check"} ${cls.icon}`} />
                        {ReactHtmlParser(confirmRefreshProgram.title)}
                      </BTypography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} direction="row" justifyContent={"flex-start"} alignItems={"center"} className={cls.shepherdWrapper}>
                    <Grid item>
                      <BTypography variant="h5" className={cls.fieldType}>Shepherd: &nbsp;</BTypography>
                    </Grid>
                    <Grid item>
                      <UserAvatar size={30} src={confirmRefreshProgram.shepherdUser.avatarURL} name={confirmRefreshProgram.shepherdUser.name} nameVariant="h5" badgeSrc={confirmRefreshProgram.shepherdUser.badgeURL} />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} direction="row" justifyContent={"flex-start"} alignItems={"center"} className={cls.shepherdWrapper}>
                    <Grid item>
                      <BTypography variant="h5" className={cls.fieldType}>Status: &nbsp;</BTypography>
                    </Grid>
                    <Grid item>
                      <BTypography variant="h5" className={cls.status}>{confirmRefreshProgram.active && !confirmRefreshProgram.completed ? "Active" : "Inactive"}</BTypography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item className={cls.noteWrapper} justifyContent={"center"} alignItems={"center"}>
                  <Grid item>
                    <i>Note: Refreshing a program will move it from "Inactive" to "Active".</i>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item>
              <NormalButton
                component={Grid}
                item
                variant="secondary"
                color={colors.white}
                labelColor={colors.gray}
                borderColor={colors.gray}
                hoverColor={colors.darkGray}
                className={cls.confirmButton}
                onClick={onClose}
              >
                Cancel
              </NormalButton>
            </Grid>
            <Grid item className={cls.confirmButtonWrapper}>
              <NormalButton
                component={Grid}
                item
                variant="primary"
                color={colors.green}
                labelColor={colors.white}
                hoverColor={colors.darkGreen}
                className={cls.confirmButton}
                onClick={() => {
                  _refreshProgram(confirmRefreshProgram.user.id, confirmRefreshProgram.id);
                }}
              >
                Confirm Refresh
              </NormalButton>
            </Grid>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}