import React, { useState } from "react";
import { colors, NormalButton, SortableList, Blink, PathProgress, UserAvatar, FilledCircleProgress, ProgramBadge, PopTip } from "bild-ui";
import { ClickAwayListener, Grid, List, ListItemButton, Paper, Popper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { downloadRequest } from "bild-data/core/comm";
import UnassignUserProgramDialog from "modules/management/manageUsers/components/unassignUserProgramDialog";
import UpdateUserProgramShepherdDialog from "modules/management/manageUsers/components/updateUserProgramShepherdDialog";

const useStyles = makeStyles({
  certificate: { paddingLeft: "1rem", },
});

export default function UserPathList({ paths, filter, perPage, shepherds, updateShepherd, removeProgram }) {
  const cls = useStyles();
  const manageMode = shepherds && updateShepherd && removeProgram;
  const [popContext, setPopContext] = useState({});
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [confirmUserPath, setConfirmUserPath] = useState({});
  const [confirmShepherd, setConfirmShepherd] = useState({});

  function onMenuClick(e, progData) {
    setPopContext(progData);
    setMenuOpen(true);
    setMenuAnchorEl(e.currentTarget);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  return (
    <>
      <SortableList
        perPage={perPage ? perPage : 10}
        filter={filter}
        headers={manageMode ? ["Status", "Program", "", "", ""] : ["Status", "Program", "", ""]}
        spacing={manageMode ? [1,5,2,2,2] : [1,5,4,2]}
        itemPadding={"0.5rem 1rem"}
        items={paths
          .filter(x => {
            if (filter.length > 0) {
              return (
                x.title.toLowerCase().includes(filter.toLowerCase())
              );
            } else {
              return 1;
            }
          }
          )
          .sort((a,b) => b.active - a.active || b.title.localeCompare(a.title))
          .map((x, i) => {
            const color = x.programColor.webFrontendValue;
            const status = x.completed ? "Completed" : x.active ? "Active" : "Inactive";
            return ([
              [<PopTip variant="body1" sortval={status} key={status} text={status}>
                <Grid container justifyContent={"center"} alignItems={"center"}>
                  <Grid item>
                    <FilledCircleProgress
                      total={1}
                      started={x.completed}
                      completed={x.active}
                      color={x.completed ? color : x.active ? colors.lightGreen : colors.lightGray}
                    />
                  </Grid>
                </Grid>
              </PopTip>],
              [
                <UserAvatar
                  size={30}
                  icon={"fas fa-badge-check"}
                  color={color}
                  name={x.title}
                  sortval={x.title}
                  key={`a${i}`}
                />
              ],
              <>
                {x.completed && (
                  <Grid container item xs={12} alignItems={"center"}>
                    {x.badges && JSON.parse(x.badges).map((b, i) => (
                      <Grid item xs key={i}>
                        <ProgramBadge color={color} badgeURL={b.imageURL} completed={x.completed} key={i}/>
                      </Grid>
                    ))}
                    {x.completed && x.certificates && x.certificates.map((c, j) => (
                      <Grid item className={cls.certificate} key={j}>
                        <NormalButton
                          variant={"secondary"}
                          color={colors.white}
                          labelColor={color}
                          borderColor={color}
                          hoverLabelColor={colors.white}
                          hoverColor={color}
                          onClick={() => {
                            downloadRequest(c.downloadURL, c.name);
                          }}
                        >
                          <i className="fas fa-download" />
                        </NormalButton>
                      </Grid>
                    ))}
                  </Grid>
                )}
                {!x.completed && (<PathProgress
                  totalCompletedCoreSteps={x.totalCompletedCoreSteps}
                  totalInProgressCoreSteps={x.totalInProgressCoreSteps}
                  totalCoreSteps={x.totalCoreSteps}
                  totalCompletedSteps={x.totalCompletedSteps}
                  totalInProgressSteps={x.totalInProgressSteps}
                  totalSteps={x.totalSteps}
                />)}
              </>,
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <NormalButton
                    variant="primary"
                    color={colors.bildBlue}
                    hoverColor={colors.darkBildBlue}
                    labelColor={colors.white}
                    component={Blink}
                    dst="equip-user-path"
                    user_path_id={x.id}
                  >
                    View &nbsp; <i className="fas fa-chevron-right" />
                  </NormalButton>
                </Grid>
              </Grid>,
              manageMode && [<Grid container justifyContent={"flex-end"} alignItems={"center"} key={i}>
                <Grid item>
                  <PopTip text={x.completed ? "Completed Programs cannot be updated. Please contact support with any requests." : null}>
                    <NormalButton
                      variant="simple"
                      onClick={e => {
                        onMenuClick(e, x);
                      }}
                      disabled={x.completed}
                    >
                      <i className={`far ${x.completed ? "fa-horizontal-rule" : "fa-ellipsis-h"}`} />
                    </NormalButton>
                  </PopTip>
                </Grid>
              </Grid>]
            ])
          })
        }
        emptyMessage={filter ? "No programs match the filter." : "No programs currently assigned."}
      />
      <Popper open={menuOpen} anchorEl={menuAnchorEl} className={cls.popper}>
        <ClickAwayListener onClickAway={closeMenu} mouseEvent="onMouseDown">
          <Paper>
            <List dense>
              <ListItemButton
                dense
                onClick={() => {
                  closeMenu();
                  setConfirmShepherd(popContext);
                }}
              >
                <i className="fal fa-clipboard-user fa-fw" /> &nbsp; Change Shepherd
              </ListItemButton>
              <ListItemButton
                dense
                onClick={() => {
                  closeMenu();
                  setConfirmUserPath(popContext);
                }}
              >
                <i className="fal fa-badge-check fa-fw" /> &nbsp; Unassign Program
              </ListItemButton>
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
      <UnassignUserProgramDialog
        open={confirmUserPath.id ? true : false}
        onClose={() => {
          setConfirmUserPath({});
        }}
        confirmUserPath={confirmUserPath}
        removeProgram={removeProgram}
      />
      <UpdateUserProgramShepherdDialog
        open={confirmShepherd.id ? true : false}
        onClose={() => {
          setConfirmShepherd({});
        }}
        allUsers={shepherds}
        confirmShepherd={confirmShepherd}
        updateShepherd={updateShepherd}
      />
    </>
  );
}