import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import { makeStyles } from "@mui/styles";
import { BTypography, colors, FormAutocompleteField, NormalButton, UserAvatar } from "bild-ui";
import { useState } from "react";

const useStyles = makeStyles({
  dialogTitleHeader: props => ({
    background: props.pathColor,
  }),
  dialogTitle: {
    color: colors.white,
    fontWeight: "bold",
  },
  shepherdWrapper: {
    padding: "0 0 1rem 0"
  },
  fieldType: { fontWeight: "bold", minWidth: "8rem" },
  shepherdArrow: {
    padding: "0 1rem"
  },
  pathTitle: props => ({
    color: props.pathColor,
    lineHeight: "2rem",
    fontWeight: "bold",
    padding: "2rem 0 2rem 0",
    "& sup": {
      lineHeight: "1rem"
    }
  }),
  shepherdAvatar: { padding: "0 0.25rem 0 0.5rem" },
  icon: {
    padding: "0 0.5rem 0 0.3rem"
  },
  confirmDialog: { padding: "1rem 0rem 0rem 0rem" },
  confirmButton: { margin: "0.5rem 0", padding: "1rem 2rem" }
});

export default function UpdateUserProgramShepherdDialog({ open, onClose, allUsers, confirmShepherd, updateShepherd }) {
  const pathColor = confirmShepherd && confirmShepherd.programColor ? confirmShepherd.programColor.webFrontendValue : colors.darkGray;
  const cls = useStyles({ pathColor });
  const [newShepherdUserId, setNewShepherdUserId] = useState(null);

  function _updateShepherd(userId, userPathId) {
    updateShepherd(userId, userPathId, newShepherdUserId);
    _onClose();
  }

  function _onClose() {
    setNewShepherdUserId(null);
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={_onClose}
      fullWidth
      maxWidth="md"
    >
      {confirmShepherd && confirmShepherd.id && (
        <>
          <DialogTitle className={cls.dialogTitleHeader}>
            <Grid container justifyContent={"space-between"} alignItems={"center"}>
              <Grid container item xs justifyContent={"flex-start"} alignItems={"center"}>
                <Grid item>
                  <BTypography variant="h5" className={cls.dialogTitle}>
                    <i className="fal fa-clipboard-user fa-fw" /> &nbsp; Change Shepherd
                  </BTypography>
                </Grid>
              </Grid>
              <Grid item>
                <NormalButton
                  onClick={_onClose}
                  variant="secondary"
                  color={colors.transparent}
                  labelColor={colors.white}
                  hoverColor={colors.darkGray}
                  hoverLabelColor={colors.white}
                >
                  <i className="far fa-times fa-2x" />
                </NormalButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container item xs={12} direction="column" className={cls.confirmDialog} justifyContent="center" alignItems="center">
              <Grid container item justifyContent="center" alignItems="center">
                <Grid container item justifyContent="flex-start" alignItems="center">
                  <Grid item>
                    <BTypography variant="h5" className={cls.fieldType}>Learner: &nbsp;</BTypography>
                  </Grid>
                  <Grid item xs>
                    <BTypography variant="h5">
                      <UserAvatar size={30} src={confirmShepherd.user.avatarURL} name={confirmShepherd.user.name} nameVariant="h5" badgeSrc={confirmShepherd.user.badgeURL} />
                    </BTypography>
                  </Grid>
                </Grid>
                <Grid container item justifyContent="flex-start" alignItems="center">
                  <Grid item>
                    <BTypography variant="h5" className={cls.fieldType}>Program: &nbsp;</BTypography>
                  </Grid>
                  <Grid item xs className={cls.pathTitle}>
                    <BTypography variant="h5">
                      <i className={`fas ${confirmShepherd.icon ? confirmShepherd.icon : "fa-badge-check"} ${cls.icon}`} />
                      {ReactHtmlParser(confirmShepherd.title)}
                    </BTypography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12} direction="row" justifyContent={"flex-start"} alignItems={"center"} className={cls.shepherdWrapper}>
                <Grid item>
                  <BTypography variant="h5" className={cls.fieldType}>Shepherd: &nbsp;</BTypography>
                </Grid>
                <Grid item>
                  <UserAvatar size={30} src={confirmShepherd.shepherdUser && confirmShepherd.shepherdUser.avatarURL} name={confirmShepherd.shepherdUser && confirmShepherd.shepherdUser.name} nameVariant="h5" badgeSrc={confirmShepherd.shepherdUser && confirmShepherd.shepherdUser.badgeURL} />
                </Grid>
                <Grid item className={cls.shepherdArrow}>
                  <i className="fal fa-arrow-right fa-2x" />
                </Grid>
                <Grid item xs>
                  <FormAutocompleteField value={newShepherdUserId} setValue={(x)=>{setNewShepherdUserId(x)}} items={allUsers} showAvatar={true} />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item>
              <NormalButton
                component={Grid}
                item
                variant="secondary"
                color={colors.white}
                labelColor={colors.gray}
                borderColor={colors.gray}
                hoverColor={colors.darkGray}
                className={cls.confirmButton}
                onClick={_onClose}
              >
                Cancel
              </NormalButton>
            </Grid>
            <Grid item>
              <NormalButton
                component={Grid}
                item
                variant="primary"
                color={newShepherdUserId ? colors.bildBlue : colors.darkGray}
                labelColor={colors.white}
                hoverColor={colors.darkBildBlue}
                className={cls.confirmButton}
                onClick={() => {
                  _updateShepherd(confirmShepherd.user.id, confirmShepherd.id);
                }}
                disabled={!newShepherdUserId}
              >
                Confirm Change Shepherd
              </NormalButton>
            </Grid>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}