import React, { useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Blink, colors, NormalButton } from "bild-ui";
import { Drawer, Grid, Typography, useMediaQuery } from "@mui/material";
import { getMasqueradeInfo } from "bild-data/core/localStorage.js";

const useStyles = makeStyles((theme) => ({
  masqueradeMenu: {
    background: "#ff111291",
    textAlign: "center",
    padding: "0.26rem",
    color: "#FFF",
    maxWidth: "45%",
    left: "25%",
    zIndex: "1290",
    [theme.breakpoints.down('md')]: {
      padding: "0 !important",
      background: "#ce0809e3",
      maxWidth: "100% !important",
      left: "0 !important",
      zIndex: "900 !important",
    }
  },
  menuWrapper: {
    overflow: "hidden"
  },
  name: {
    color: colors.white,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  spacer: {
    padding: "0",
  },
  exitMasquerade: {
    color: "#FFF",
    fontWeight: "bold",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }
}));

function MasqueradeMenu({ userOverview }) {
  const theme = useTheme();
  const cls = useStyles();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const topPosition = useMediaQuery(theme.breakpoints.up('md'));
  const anchorPosition = topPosition ? "top" : "bottom";
  const showSearch = userOverview && userOverview.showSearch;
  const name = userOverview && userOverview.user.name;

  let masqueradeMenu = null;
  if (getMasqueradeInfo()) {
    masqueradeMenu = (
      <Drawer variant="permanent" anchor={anchorPosition} classes={{ paper: cls.masqueradeMenu }}>
        <Grid container justifyContent="center" alignItems="center" className={cls.menuWrapper}>
          <Grid container item xs={topPosition ? 5 : 7} justifyContent={"flex-end"}>
            <Grid item>
              <NormalButton
                component={Blink}
                dst={"search"}
                search_term={name}
                variant="simple"
                hoverColor={colors.bildBlue}
                disabled={!showSearch}
              >
                <Typography variant={topPosition ? "h6" : "body2"} className={cls.name}>
                  {name}
                </Typography>
              </NormalButton>
            </Grid>
          </Grid>
          <Grid item xs={topPosition ? 2 : 1} className={cls.spacer}>
            <i className="fal fa-horizontal-rule fa-rotate-90" />
          </Grid>
          <Grid container item xs={topPosition ? 5 : 4} justifyContent={"flex-start"}>
            <Grid item>
              <NormalButton
                component={buttonDisabled ? null : Blink}
                href={"/masquerade"}
                onClick={()=>{setButtonDisabled(true)}}
                disabled={buttonDisabled}
                variant="simple"
                hoverColor={anchorPosition === "top" ? colors.red : colors.darkRed}
              >
                <Typography variant={topPosition ? "h6" : "body2"} className={cls.exitMasquerade}>
                  <i className={"fas fa-ban fa-fw fa-md"} /> {topPosition ? "Stop Masquerading" : "Stop Masq"}
                </Typography>
              </NormalButton>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    );
  }

  return masqueradeMenu;
}

export default MasqueradeMenu;
