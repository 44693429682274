import React, { useState, useEffect } from "react";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import { equipReviewData, managerData, organizationsData } from "bild-data/index.js";
import StaffOrganizationLearnersReview from "./staffOrganizationLearnersReview";
import { useParams } from "react-router-dom";
import { toaster } from "presentational/toasts/toasts";


export default function StaffOrganizationLearnersReviewLoader({}) {
  const { organization_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [learners, setLearners] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [org, setOrg] = useState({});
  const [orgGroups, setOrgGroups] = useState([]);

  useEffect(()=>{
    setPageTitle("Staff Learners Review");

    equipReviewData.getOrganizationLearnersForReview(organization_id, _setData);
    managerData.getManagedOrganization(organization_id, setOrg);
    managerData.getManagedUsers(
      x => {
        setAllUsers(x.users);
      }
    );
    organizationsData.loadOrganizationGroups(organization_id, d => {
      setOrgGroups(d);
    });
  },[]);

  function _setData(d) {
    setLearners(d.learnersWithUserPaths);
    setLoading(false);
  }

  function updateShepherd(userId, userPathId, shepherdUserId) {
    let data = {
      id: userId,
      userPathsToUpdate: [{ id: userPathId, shepherdUser: { id: shepherdUserId } }]
    };
    managerData.updateManagedUser(userId, data, x => {
      toaster.success(`Program Shepherd Updated!`);
      equipReviewData.getOrganizationLearnersForReview(organization_id, _setData);
    });
  }

  function removeProgram(userId, userPathId) {
    let data = {
      id: userId,
      userPathsToRemove: [{ id: userPathId }]
    };
    managerData.updateManagedUser(userId, data, x => {
      toaster.success(`Program Unassigned`);
      equipReviewData.getOrganizationLearnersForReview(organization_id, _setData);
    });
  }

  function refreshProgram(userId, userPathId) {
    let data = {
      id: userId,
      userPathsToUpdate: [{ id: userPathId, refreshProgram: true }]
    };
    managerData.updateManagedUser(userId, data, x => {
      toaster.success(`Program Refreshed!`);
      equipReviewData.getOrganizationLearnersForReview(organization_id, _setData);
    });
  }

  if (loading) return <LoadingScreen />;
  return (
    <StaffOrganizationLearnersReview
      organization={org}
      orgGroups={orgGroups}
      learners={learners}
      allUsers={allUsers}
      updateShepherd={updateShepherd}
      removeProgram={removeProgram}
      refreshProgram={refreshProgram}
    />
  );
}