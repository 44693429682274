import React, { useState, useEffect } from "react";
import { colors, FilterTextField, SortableList, Blink, NormalButton, BTypography, SupportDialog, TreeDiagram, Expander } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { Grid, Tooltip } from "@mui/material";
import { ManageGroupsDialogLoader } from "modules";
import AddOrganizationDialog from "./components/addOrganizationDialog.js";
import OrganizationsReportPopper from "./components/organizationsReportPopper.js";
import OrganizationActionsPopper from "./components/organizationActionsPopper.js";

const useStyles = makeStyles({
  header: { padding: "1rem", marginBottom: "1rem", backgroundColor: colors.white },
  subHeader: { padding: "1rem 0 0 0" },
  orgs: { padding: "0.5rem 0" },
  org: { "&:hover": { cursor: "pointer !important" } },
  orgButton: { padding: "0 0.25rem" },
  popperArrow: { color: colors.bildBlue },
  tooltip: { backgroundColor: colors.lightGray, color: colors.black, border: `1px solid ${colors.bildBlue}` },
  rolesIcon: { color: colors.bildBlue },
  roles: { margin: "0", padding: "0.1rem 0.1rem 0.1rem 0.5rem" },
  button: { padding: "0 0 0.25rem 0.5rem" },
  diagram: { backgroundColor: colors.white, marginBottom: "1rem" },
  detailButton: { height: "100%", width: "100%", maxWidth: "15rem" }
});

function ManageOrganizations({ orgs, equipOrgs, orgTree, users, setUsers, createOrganization }) {
  const cls = useStyles();

  const [orgItems, setOrgItems] = useState([]);
  const [showTree, setShowTree] = useState(false);
  const [groupDialog, setGroupDialog] = useState(false);
  const [orgDialog, setOrgDialog] = useState(false);
  const [updateOrgDialog, setUpdateOrgDialog] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const [orgName, setOrgName] = useState(null);
  const [orgOpen, setOrgOpen] = useState(false);
  const [isAntioch, setIsAntioch] = useState(false);
  const [isEquip, setIsEquip] = useState(false);
  const [orgAnchorEl, setOrgAnchorEl] = useState(null);
  const [reportOpen, setReportOpen] = useState(false);
  const [reportAnchorEl, setReportAnchorEl] = useState(null);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    // map raw org data into list format
    setOrgItems(
      orgs
        .filter(x => {
          if (filter.length > 0) {
            return x.name.toLowerCase().includes(filter.toLowerCase());
          } else {
            return 1;
          }
        })
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(x => [
          x.name,
          <Grid container justifyContent="flex-end">
            {x.localEntityType && (
              <Grid item className={cls.orgButton}>
                <NormalButton
                  component={Blink}
                  dst={"equip-review-organization-learners"}
                  organization_id={x.id}
                  variant="secondary"
                  color={colors.white}
                  labelColor={colors.bildBlue}
                  borderColor={colors.bildBlue}
                  hoverColor={colors.bildBlue}
                  hoverLabelColor={colors.white}
                >
                  Equip Programs
                </NormalButton>
              </Grid>
            )}
            <Grid item className={cls.orgButton}>
              <NormalButton
                component={Blink}
                dst={x.localEntityType ? "manage-organization" : "manage-antioch-organization"}
                organization_id={x.id}
                variant="primary"
                color={colors.bildBlue}
                hoverColor={colors.blue}
                labelColor={colors.white}
              >
                Manage
              </NormalButton>
            </Grid>
            <Grid item>
              <NormalButton
                variant="simple"
                onClick={e => {
                  setOrgId(x.id);
                  setOrgName(x.name);
                  setOrgOpen(true);
                  setIsAntioch(x.antiochSchool);
                  setIsEquip(x.equip);
                  setOrgAnchorEl(e.currentTarget);
                }}
              >
                <i className="far fa-ellipsis-h" />
              </NormalButton>
            </Grid>
          </Grid>
        ])
    );
  }, [orgs, filter]);

  function clear() {
    setOrgId(null);
    setOrgName(null);
  }

  return (
    <Grid container className={cls.wrapper}>
      <Grid container item xs={12} className={cls.header}>
        <Grid item md={4} sm={12} xs={12}>
          <BTypography variant="h4">Manage Organizations</BTypography>
        </Grid>
        <Grid item md={8} sm={12} xs={12}>
          <Grid container alignItems="center" justifyContent="flex-end" className={cls.details}>
            <Grid item>
              <FilterTextField
                value={filter}
                placeholder="Search"
                onChange={e => setFilter(e.target.value)}
                onClear={() => {
                  setFilter("");
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={cls.subHeader}>
          <Grid container alignItems="center" justifyContent="flex-end" className={cls.details}>
            <Grid item className={cls.button}>
              <NormalButton
                variant="secondary"
                color={colors.white}
                hoverColor={colors.bildBlue}
                labelColor={colors.bildBlue}
                borderColor={colors.bildBlue}
                onClick={e => {
                  setReportOpen(true);
                  setReportAnchorEl(e.currentTarget);
                }}
              >
                Data Reports &nbsp; <i className={`fas fa-caret-${reportOpen ? "up" : "down"}`} />
              </NormalButton>
            </Grid>
            {orgTree && orgTree.length > 0 && (
              <Grid item className={cls.button}>
                <NormalButton
                  onClick={() => {
                    setShowTree(!showTree);
                  }}
                  variant="secondary"
                  color={colors.white}
                  hoverColor={colors.bildBlue}
                  labelColor={colors.bildBlue}
                  borderColor={colors.bildBlue}
                >
                  {showTree ? "Hide " : "Show "} Diagram
                </NormalButton>
              </Grid>
            )}
            <Grid item className={cls.button}>
              <NormalButton
                onClick={() => {
                  setGroupDialog(true);
                }}
                variant="primary"
                color={colors.bildBlue}
                hoverColor={colors.darkBildBlue}
                labelColor={colors.white}
                className={cls.detailButton}
              >
                Manage Groups
              </NormalButton>
            </Grid>
            {equipOrgs.some(x => x.canHaveChildren === true) && (
              <Grid item className={cls.button}>
                <NormalButton
                  onClick={() => {
                    clear();
                    setOrgDialog(true);
                  }}
                  variant="primary"
                  color={colors.bildBlue}
                  hoverColor={colors.darkBildBlue}
                  labelColor={colors.white}
                  className={cls.detailButton}
                >
                  Add Organization
                </NormalButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        {orgTree && orgTree.length > 0 && (
          <Expander expandIn={showTree}>
            <Grid item xs={12} className={cls.diagram}>
              <TreeDiagram data={orgTree} legend={true} />
            </Grid>
          </Expander>
        )}
      </Grid>
      <Grid item xs={12}>
        <SortableList
          perPage={10}
          filter={filter}
          headers={["Name", ""]}
          items={orgItems}
          fixedHeight={"55vh"}
          itemPadding={"0.5rem 1rem"}
        />
      </Grid>

      <ManageGroupsDialogLoader
        open={groupDialog}
        onClose={() => {
          setGroupDialog(false);
        }}
      />
      <AddOrganizationDialog
        open={orgDialog}
        closeDialog={() => {
          setOrgDialog(false);
        }}
        orgs={equipOrgs.filter(x => x.canHaveChildren === true)}
        users={users}
        setUsers={setUsers}
        createOrganization={createOrganization}
      />
      <OrganizationActionsPopper
        open={orgOpen}
        setOpen={setOrgOpen}
        anchorEl={orgAnchorEl}
        orgId={orgId}
        isAntioch={isAntioch}
        isEquip={isEquip}
        updateOrg={() => {
          setUpdateOrgDialog(true);
        }}
      />
      <OrganizationsReportPopper open={reportOpen} setOpen={setReportOpen} anchorEl={reportAnchorEl} orgTree={orgTree} />
      <SupportDialog
        open={updateOrgDialog}
        closeDialog={() => {
          setUpdateOrgDialog(false);
        }}
        options={[`I would like to update information for ${orgName}.`]}
      />
    </Grid>
  );
}

export default ManageOrganizations;
