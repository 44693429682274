// "/profile/:user_id" UserProfileLoader
// "/dashboard" DashboardLoader
// "/enrollments/:enrollment_id/statement" EnrollmentStatementLoader
// "/enrollments/:enrollment_id" EnrollmentLoader
// "/courses/:course_id/enrollments/:enrollment_id" CourseLoader
// "/courses/:course_id/competency_proofs/:competency_proof_id" CourseLoader
// "/review/:review_type/users/:user_id" ReviewUserLoader
// "/review/:review_type/organizations/:organization_id" ReviewOrganizationLoader
// "/review/:review_type" ReviewLoader
// "/organizations/:organization_id/users" OrganizationLoader
// "/organizations/:organization_id/admissions" OrganizationLoader
// "/organizations" OrganizationsLoader
// "/search/:value" SearchLoader
// "/search" SearchLoader
// "/folio" FolioLoader
// "/library/:book_id" ReaderLoader
// "/library" LibraryLoader
// "/translations" Translations
// "/policies/:policy" PoliciesLoader
// "/masquerade/:user_id" UserLoader
// "/masquerade" UserLoader
// "/logout" UserLoader
// "/login" UserLoader
// "/" DashboardLoader

export default function blink(dst, options) {
  let href;
  switch (dst) {
    //// MANAGEMENT
    case "manage-users":
      // "/manage/users" ManageUsersLoader
      href = `/manage/users`;
      if (options.organization_id) href += `?orgId=${options.organization_id}`;
      break;
    case "manage-user":
      // "/manage/users/:user_id" ManageUserLoader
      href = `/manage/users/${options.user_id}`;
      break;
    case "manage-organization":
      // "/manage/organizations/:organization_id" ManageOrganizationLoader
      href = `/manage/organizations/${options.organization_id}`;
      if (options.tab) href += `?t=${options.tab}`;
      break;
    case "manage-antioch-organization":
      // "/manage/antioch/organizations/:organization_id" ManageAntiochOrganizationLoader
      href = `/manage/antioch/organizations/${options.organization_id}`;
      if (options.tab) href += `?t=${options.tab}`;
      break;
    case "manage-organization-activity":
      // "/manage/organizations/:organization_id/recent_activity" OrganizationRecentActivityLoader
      href = `/manage/organizations/${options.organization_id}/recent_activity`;
      break;
    case "manage-organization-world-map":
      // "/manage/organizations/:organization_id/world_map" OrganizationWorldMapLoader
      href = `/manage/organizations/${options.organization_id}/world_map`;
      break;
    case "available-certificates":
      // "/certificates/available" AvailableCertificatesLoader
      href = `/certificates/available`;
      break;
    case "all-user-certificates":
      // "/certificates/all_user_certificates" AllCertificatesLoader
      href = `/certificates/all_user_certificates`;
      break;
    //// FUNDRAISING
    case "fundraising":
      // "/fundraising" FundraisingItemsLoader
      href = `/fundraising`;
      break;
    case "fundraising-items":
      // "/fundraising/items/:fundraising_item_id" FundraisingItemLoader
      href = `/fundraising/items/${options.fundraising_item_id}`;
      break;
    case "complete-fundraising-task":
      // "/fundraising/tasks/:fundraising_task_id/complete" FundraisingCompleteTaskLoader
      href = `/fundraising/tasks/${options.fundraising_task_id}/complete`;
      break;
    //// EQUIP
    case "equip-user-path":
      // "/equip/user_paths/:user_path_id" UserPathLoader
      href = `/equip/user_paths/${options.user_path_id}`;
      if (options.breadcrumb) href += `?bc=${options.breadcrumb}`;
      break;
    case "equip-user-path-item":
      // "/equip/user_path_items/:user_path_item_id" UserPathItemLoader
      href = `/equip/user_path_items/${options.user_path_item_id}`;
      if (options.breadcrumb) href += `?bc=${options.breadcrumb}`;
      break;
    case "equip-user-learning-item":
      // TODO create this Loader
      // "/equip/user_path_items/:user_learning_item_id" UserLearningItemLoader
      href = `/equip/user_learning_items/${options.user_learning_item_id}`;
      break;
    case "equip-learner":
      // "/equip/learner/:user_id" LearnerLoader
      href = `/equip/learner/${options.user_id ? options.user_id : ""}`;
      break;
    case "equip-leader":
      // "/equip/review" ReviewLoader
      href = `/equip/review`;
      break;
    case "equip-review-shared":
      // "/equip/review/shared" ReviewLoader
      href = `/equip/review/shared`;
      break;
    case "equip-review-shared-paths":
      // "/equip/review/shared/paths" SharedPathsLoader
      href = `/equip/review/shared/paths`;
      if (options.group_id) href += `?group_id=${options.group_id}`;
      break;
    case "equip-review-shared-path":
      // "/equip/review/shared/paths/:path_id/groups/:group_id" SharedPathLoader
      href = `/equip/review/shared/paths/${options.path_id}/groups/${options.group_id}`;
      break;
    // MCCEE Shepherding Tree
    case "equip-review-shepherding-coaches":
      // "/equip/review/shepherding_coaches" ShepherdingCoachesLoader
      href = `/equip/review/shepherding_coaches`;
      break;
    case "equip-review-shepherding-overview":
      // "/equip/review/users/:user_id/shepherding_overview" ShepherdingOverviewLoader
      // "/equip/review/shepherding_overview" ShepherdingOverviewLoader
      if (options.user_id) {
        href = `/equip/review/users/${options.user_id}/shepherding_overview`;
      } else {
        href = `/equip/review/shepherding_overview`;
      }
      break;
    case "equip-review-shepherding-tree":
      // "/equip/review/users/:user_id/shepherding_tree" ShepherdingTreeLoader
      // "/equip/review/shepherding_tree" ShepherdingTreeLoader
      if (options.user_id) {
        href = `/equip/review/users/${options.user_id}/shepherding_tree`;
      } else {
        href = `/equip/review/shepherding_tree`;
      }
      break;
    case "equip-review-organization-learners":
      // "/equip/review/organizations/:organization_id/learners" StaffOrganizationLearnersReviewLoader
      href = `/equip/review/organizations/${options.organization_id}/learners`;
      break;
      
    // NETWORK PARTNERS
    case "network-partners":
      // "/network_partners" NetworkPartnersLoader
      href = `/network_partners`;
      break;
    case "network-partner":
      // "/network_partners/:network_partner_id" NetworkPartnerLoader
      href = `/network_partners/${options.network_partner_id}`;
      break;
    case "civilizations":
      // "/civilizations" NetworkPartnerCivlizationsLoader
      href = `/civilizations`;
      break;
    case "civilization":
      // "/civilizations/:civilization_id" NetworkPartnerCivlizationLoader
      href = `/civilizations/${options.civilization_id}`;
      break;
    // Equip Drive
    case "equip-drive":
      // "/drive/:in_app_local_id" DriveLoader
      href = `/drive/${options.in_app_local_id ? options.in_app_local_id : ""}`;
      break;
    case "user-certificates":
      // "/certificates" UserCertificatesLoader
      href = `/certificates`;
      break;
    case "organization-user-certificates":
      // "/certificates/organizations/:organization_id" OrgUserCertificatesLoader
      href = `/certificates/organizations/${options.organization_id}`;
      break;
    case "home":
      // "/home" HomeLoader
      href = "/home";
      break;
    //// AntiochSchool
    case "dashboard":
      // "/dashboard" DashboardLoader
      href = "/dashboard";
      break;
    case "profile":
      // "/profile/:user_id" UserProfileLoader
      href = `/profile${options.user_id ? "/" + options.user_id : ""}`;
      break;
    case "profile-records":
      // "/profile/:user_id/records" UserRecordsLoader
      href = `/profile${options.user_id ? "/" + options.user_id : ""}/records`;
      break;
    case "user-admin":
      // "/users/:user_id/admin" UserAdminLoader
      href = `/users/${options.user_id}/admin`;
      break;
    case "user-admin-competencies":
      // "/users/:user_id/competency_proofs" UserAdminCompetencyLoader
      if (options.user_id) href = `/users/${options.user_id}/competency_proofs`;
      else href = `/all_competency_proofs_overview`;
      break;
    case "user-admin-courses":
      // "/users/:user_id/courses" UserAdminCourseLoader
      if (options.user_id) href = `/users/${options.user_id}/courses`;
      else href = `/all_courses_overview`;
      break;
    case "user-audit-antioch-courses":
      // "/audit_courses" AuditAntiochCoursesLoader
      if (options.user_id) href = `/audit_courses/users/${options.user_id}`;
      else href = `/audit_courses`;
      break;
    case "user-audit-antioch-course":
      // "/audit_courses/:course_id/users/:user_id/competency_sets/:competency_set_id?competency_proof_id=:competency_proof_id" AuditAntiochCourseLoader.js
      href = `/audit_courses/${options.course_id}/users/${options.user_id}/competency_sets/${options.competency_set_id}`;
      if (options.competency_proof_id) href += `?competency_proof_id=${options.competency_proof_id}`;
      break;
    case "organization-admin":
      // "/organizations/:organization_id/admin" OrganizationAdminLoader
      href = `/organizations/${options.organization_id}/admin`;
      break;
    case "enrollment":
      // "/enrollments/:enrollment_id" EnrollmentLoader
      href = `/enrollments/${options.enrollment_id || -1}`;
      break;
    case "enrollment-statement":
      // "/enrollments/:enrollment_id/statement" EnrollmentStatementLoader
      href = `/enrollments/${options.enrollment_id || -1}/statement`;
      break;
    case "reports-organization-student-progress":
      // "/reports/organization_student_progress/:organization_id" OrganizationStudentProgressLoader
      // Can be null, id, or "all"
      href = `/reports/organization_student_progress/${options.organization_id || ""}`;
      break;
    case "reports-organization-module-progress":
      // "/reports/organization_module_progress/:organization_id" OrganizationCourseProgressLoader
      // Can be null, id, or "all"
      href = `/reports/organization_module_progress/${options.organization_id || ""}`;
      break;
    case "reports-total-student-progress":
      // "/reports/total_student_progress" TotalStudentProgressLoader
      href = `/reports/total_student_progress`;
      break;
    case "course":
      // "/courses/:course_id/enrollments/:enrollment_id" CourseLoader
      // "/courses/:course_id/competency_proofs/:competency_proof_id" CourseLoader
      href = `/courses/${options.course_id || -1}`;
      if (options.enrollment_id) href += `/enrollments/${options.enrollment_id}`;
      else href += `/competency_proofs/${options.competency_proof_id || -1}`;
      if (options.return_review_type) href += `?view=${options.return_review_type}`;
      break;
    case "review":
      // "/review/:review_type/users/:user_id" ReviewUserLoader
      // "/review/:review_type/organizations/:organization_id" ReviewOrganizationLoader
      // "/review/:review_type" ReviewLoader
      href = `/review/${options.review_type || -1}`;
      if (options.organization_id) href += `/organizations/${options.organization_id}`;
      else if (options.user_id) href += `/users/${options.user_id}`;
      if (options.return_org_id) href += `?org_id=${options.return_org_id}`;
      break;
    case "trainees_review":
      href = `/review/coach`;
      break;
    case "all_trainees_review":
      href = `/review/coach/all`;
      break;
    case "trainee_review":
      href = `/review/trainee/${options.user_id}`;
      break;
    case "training_assessment":
      href = `/review/training_assessments/${options.training_assessment_id}`;
      break;
    case "training_modules_review":
      href = `/review/trainees/${options.user_id}/training_modules`;
      break;
    case "training_module_review":
      href = `/review/trainees/${options.user_id}/training_modules/${options.training_module_id}`;
      break;
    case "delinquent_review":
      href = `/review/delinquent`;
      if (options.user_id) href += `/${options.user_id}`;
      if (options.name) href += `?name=${options.name}`;
      break;
    case "organizations":
      // "/organizations" OrganizationsLoader
      href = "/organizations";
      break;
    case "organization-users":
      // "/organizations/:organization_id/users" OrganizationLoader
      href = `/organizations/${options.organization_id || -1}/users`;
      break;
    case "admissions":
      // "/organizations/:organization_id/admissions" OrganizationLoader
      href = `/organizations/${options.organization_id || -1}/admissions`;
      break;
    case "system-recent-activity":
      // "/reports/system_recent_activity" SystemRecentActivityLoader
      href = "/reports/system_recent_activity";
      break;
    case "system-world-map":
      // "/world_map" WorldMapLoader
      href = "/world_map";
      break;
    case "system-beta-tester-leaderboard":
      // "/bt_leaderboard" BetaTesterLeaderboardLoader
      href = "/bt_leaderboard";
      break;
    case "search":
      // "/search/:value" SearchLoader
      // "/search" SearchLoader
      href = "/search";
      if (options.search_term) href += `/${options.search_term}`;
      break;
    case "staff-search":
      // "/staff/search/:value" StaffSearchLoader
      // "/staff/search" StaffSearchLoader
      href = "/staff/search";
      if (options.search_term) href += `/${options.search_term}`;
      break;
    case "folio":
      // "/folio" FolioLoader
      href = "/folio";
      break;
    case "library":
      // "/library/:book_id" ReaderLoader
      // "/library" LibraryLoader
      href = "/library";
      if (options.book_id) href += `/${options.book_id}`;
      if (options.language_code) href += `?language=${options.language_code}`;
      if (options.page_number) href += `?page=${options.page_number}`;
      break;
    case "translations":
      // "/translations" Translations
      href = "/translations";
      break;
    case "policy":
      // "/policies/:policy" PoliciesLoader
      href = `/policies/${options.policy || -1}`;
      break;
    case "messages":
      // "/messages" MessageLoader
      href = `/messages`;
      break;
    case "masquerade":
      // "/masquerade" UserLoader
      // "/masquerade/:user_id" UserLoader
      href = `/masquerade`;
      if (options.user_id) href += `/${options.user_id}`;
      break;
    case "logout":
      // "/logout" UserLoader
      href = `/logout`;
      break;
    case "login":
      // "/login" UserLoader
      href = `/login`;
      if (options.error) href += `?error=${options.error}`;
      if (options.support_request) href += `?support_request=${options.support_request}`;
      if (options.support_password) href += `?support_password=${options.support_password}`;
      break;
    default:
      // "/" DashboardLoader
      if (dst) {
        href = `/${dst}`;
        if (options.id) href += `/${options.id}`;
      } else {
        href = "/";
      }
      break;
  }

  return href;
}
