import { useEffect, useRef } from "react";

// -> Thanks to brandonscript @ https://stackoverflow.com/a/30106551/4966156
export function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return window.btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      return String.fromCharCode("0x" + p1);
    })
  );
}

export function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    window.atob(str)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}

export function isValidEmail(email) {
  return email
    .toLowerCase()
    .match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
}

export function setPageTitle(title) {
  document.title = "BILD Cloud | " + title.replace(/(<([^>]+)>)/gi, "");
}

// PAGE FILTER (super simple but super effective!)
export function filterList(list, filter, filterItem) {
  var updatedList = list;
  updatedList = updatedList.filter(i => {
    return i[filter].toLowerCase().search(filterItem.toLowerCase()) !== -1;
  });
  return updatedList;
}

// LOAD 3RD PARTY SCRIPTS (GDrive/Dropbox/etc)
// -> Thanks to Yash Thakur @ https://stackoverflow.com/a/49797019
export function loadScript(url, id, data, callback) {
  let script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.id = id;

  // add in special data attributes if passed in
  if (data) {
    for (var i = 0; i < data.length; i++) {
      let d = data[i];
      script.setAttribute(d[0], d[1]);
    }
  }

  // Handle IE
  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    // Handle normal, good browsers
    script.onload = function() {
      callback();
    };
  }

  script.src = url;
  // Add the script in the head element, keep in mind you will may need to reload components using 'window.*' after this completes
  document.getElementsByTagName("head")[0].appendChild(script);
}

export function timeElapsed(past, present) {
  // Taken from https://stackoverflow.com/questions/6108819/javascript-timestamp-to-relative-time-eg-2-seconds-ago-one-week-ago-etc-best

  const elapsed = present - past;

  const oneMinute = 60000; // 60 * 1000;
  if (elapsed < oneMinute) {
    // const seconds = Math.round(elapsed / 1000);
    // return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    return `Less than a minute ago`;
  }

  const oneHour = 3600000; // oneMinute * 60;
  if (elapsed < oneHour) {
    const minutes = Math.round(elapsed / oneMinute);
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  }

  const oneDay = 86400000; // oneHour * 24;
  if (elapsed < oneDay) {
    const hours = Math.round(elapsed / oneHour);
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  }

  const twoWeeks = 1209600000; // oneWeek * 2;
  if (elapsed < twoWeeks) {
    // how many days ago != how many 24h ago
    const currentDateTime = new Date(present);
    const currentDate = currentDateTime.getDate();

    const pastDateTime = new Date(past);
    const pastDate = pastDateTime.getDate();

    let days = currentDate - pastDate;
    // elapsed < 14 * 24h so this logic is sufficient to decide if straddling 2 months
    if (pastDate > currentDate) {
      const daysInPastMonth = new Date(pastDateTime.getFullYear(), pastDateTime.getMonth(), 0).getDate();
      days += daysInPastMonth; // days = currentDate + daysInPastMonth - pastDate
    }

    return days > 1 ? `${days} days ago` : "yesterday";
  }

  const oneWeek = 604800000; // oneDay * 7;
  const oneMonth = 2626560000; // oneDay * 30.4;
  if (elapsed < oneMonth) {
    const weeks = Math.round((elapsed / oneWeek) * 2) / 2;
    const weeksDisplay = weeks % 1 === 0 ? weeks.toFixed() : weeks.toFixed(1);
    return `${weeksDisplay} week${weeks > 1 ? "s" : ""} ago`;
  }

  const oneYear = 31536000000; // msPerDay * 365;
  if (elapsed < oneYear) {
    const months = Math.round((elapsed / oneMonth) * 2) / 2;
    const monthsDisplay = months % 1 === 0 ? months.toFixed() : months.toFixed(1);
    return `${monthsDisplay} month${months > 1 ? "s" : ""} ago`;
  }

  let years = Math.floor(elapsed / oneYear);

  let monthsString = "";
  const leftover = elapsed % oneYear;
  if (leftover >= oneMonth / 2) {
    if (leftover >= oneMonth * 11.5) {
      years += 1;
    } else {
      const months = Math.round(leftover / oneMonth);
      monthsString = `${months} month${months > 1 ? "s" : ""} `;
    }
  }

  return `${years} year${years > 1 ? "s" : ""} ${monthsString}ago`;
}

export function daysElapsed(past, present) {
  return Math.floor((present - past) / 86400000);
}

export function getInputDateFromBackendDate(date) {
  let d = new Date(date);
  d = d.toISOString().substring(0, 10);

  return d;
}

export function getAbbreviatedDate(date) {
  let d = date.split(" ");
  let r = [];

  for (let i=0; i<d.length; i++) {
    let v = d[i];
    if (i===0) {
      v = v.substring(0, 3);
    }
    r.push(v);
  }

  return r.join(" ");
}

export function uniqueArrayByObjectKey(arr, key) {
  // Return an array with unique values using a given key
  let r = [];
  let uniques = [];
  for (let i=0; i<arr.length; i++) {
    let a = arr[i];
    if (!uniques.includes(a[key])) {
      uniques.push(a[key]);
      r.push(a);
    }
  }

  return r;
}

export function uniqueItemsInArray(arr) {
  // Return an array with unique values
  let r = [];
  let uniques = [];
  for (let i=0; i<arr.length; i++) {
    let a = arr[i];
    if (!uniques.includes(a)) {
      uniques.push(a);
      r.push(a);
    }
  }

  return r;
}

export function sortItemsInArray(arr) {
  // Return a sorted array
  let r = arr.sort((a, b) => {
    // set default sort values
    let aVal = a;
    let bVal = b;

    if (!isNaN(aVal)) {
      // 1. number sort
      return aVal - bVal;
    } else if (!isNaN(Date.parse(aVal))) {
      // 2. date sort
      return new Date(aVal) - new Date(bVal);
    } else {
      // 3. text sort
      return aVal.localeCompare(bVal);
    }
  });

  return r;
}

export function getSortval(rootVal) {
  // if this is an Array object, try to get the key values. If not, try child array and finally escape to sortable "zzz"
  // NOTE: Must check for not null (and possibly undefined), otherwise 0 breaks this check
  if (Array.isArray(rootVal)) {
    if (rootVal[0].props.sortval !== null && typeof rootVal[0].props.sortval !== 'undefined' && rootVal[0].props.sortval.length > 0) {      
      return rootVal[0].props.sortval;
    } else if (rootVal[0].props.children[0] && rootVal[0].props.children[0].props.sortval !== null && typeof rootVal[0].props.children[0].props.sortval !== 'undefined' && rootVal[0].props.children[0].props.sortval.length > 0) {
      return rootVal[0].props.children[0].props.sortval;
    } else {
      return "zzz";
    }
  } else {
    if (rootVal !== null && typeof rootVal !== 'undefined' && rootVal.length > 0) {
      return rootVal;
    } else {
      return "zzz";
    }
  }
}

export function sortMultiTypeArrayVals(aVal, bVal) {
  if (!isNaN(aVal)) {
    // 1. number sort
    return aVal - bVal;
  } else if (!isNaN(Date.parse(aVal))) {
    // 2. date sort
    return new Date(aVal) - new Date(bVal);
  } else {
    // 3. text sort
    return aVal.localeCompare(bVal);
  }
}

// Thanks Dan Abramov => https://overreacted.io/making-setinterval-declarative-with-react-hooks
export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let intervalId = setInterval(tick, delay);
      return () => clearInterval(intervalId);
    }
  }, [delay]);
}

// function testElapsed(past, present) {
//   console.log(past, present, timeElapsed(new Date(past), new Date(present)));
// }
