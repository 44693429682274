import React, { useState } from "react";
import { colors, NormalButton, BTypography, SupportDialog, UserAvatar, FilterTextField, OrgGroupsDiagram } from "bild-ui";
import { createStyles, makeStyles, useTheme } from "@mui/styles";
import { Grid } from "@mui/material";
import AddUserItemDialog from "./components/addUserItemDialog.js";
import MessagesDialogLoader from "modules/message/messagesDialogLoader.js";
import { UserPathList, UserPathThumbnails } from "equip/index.js";
import UserShepherding from "./components/userShepherding.js";
import UserFileActivity from "./components/userFileActivity.js";
import UserProgramCompletionActivity from "./components/userProgramCompletionActivity.js";
import UserOrganizations from "./components/userOrganizations.js";
import UserCertificatesDialog from "./components/userCertificatesDialog.js";

const useStyles = makeStyles((theme) =>
  createStyles({
    empty: { padding: "1rem", color: colors.darkGray },
    header: { padding: "1rem 0.5rem", background: colors.white },
    lastSeen: { fontStyle: "italic", padding: "0 0.5rem" },
    emailWrapper: { padding: "0.5rem 0" },
    email: { padding: "0.25rem 0.5rem", borderRadius: "0.2rem", border: `0.1rem solid ${colors.lightGray}`},
    userDetails: { textAlign: "right", padding: "0 1rem" },
    body: { paddingBottom: "2rem" },
    sectionWrapper: { 
      padding: "0.5rem 0.5rem 0 0",
      [theme.breakpoints.down('md')]: {
        padding: "0.25rem !important"
      },
    },
    sectionWrapperLeft: { paddingLeft: "0.5rem" },
    section: { padding: "0.5rem", background: colors.white },
    sectionHeader: { padding: "0.5rem" },
    sectionInner: { minHeight: "20rem", maxHeight: "32rem", overflowY: "auto", },
    item: {
      backgroundColor: colors.white,
      border: "1px solid",
      borderRadius: `4px`,
      padding: "1rem",
      height: "100%"
    },
    progress: { padding: "0.5rem 1rem 0 0" },
    avatar: { padding: "0 0.25rem 0 0.5rem" },
    shepherdAvatar: { padding: "0 0.25rem 0 0.5rem" },
    diagram: { marginTop: "-3rem", marginRight: "-4rem" },
    buttons: { padding: "0.5rem" },
    button: { paddingLeft: "0.5rem" },
    filter: { paddingRight: "1rem", },
  }));

export default function ManageUser({ user, certificates, shepherds, orgGroups, updateUser, activityData, shepherdingData, updateShepherd, removeProgram }) {
  const theme = useTheme();
  const cls = useStyles(theme);
  const [pathDialog, setPathDialog] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [channelUsers, setChannelUsers] = useState(false);
  const [thumbnail, setThumbnail] = useState(false);
  const [certDialog, setCertDialog] = useState(false);
  const [filter, setFilter] = useState("");

  function _addPaths(s, p) {
    updateUser(s, p);
    setPathDialog(false);
  }

  function _showMessages(users) {
    setChannelUsers(users);
    setShowMessages(true);
  }

  return (
    <Grid container>
      <Grid container item xs={12} className={cls.header}>
        <Grid container item xs={8} justifyContent="flex-start" alignItems="center">
          <Grid item className={cls.avatar}>
            <UserAvatar size={100} src={user.avatarURL} name={user.name} badgeSrc={user.badgeURL} nameVariant="h4" />
          </Grid>
        </Grid>
        <Grid container item xs justifyContent="flex-end" alignItems="flex-end" className={cls.userDetails}>
          <Grid item xs={12} className={cls.lastSeen}>
            <BTypography variant="body2">
              Last Seen: {user.lastLoginDate ? user.lastLoginDate : "n/a"}
            </BTypography>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            <Grid item className={cls.emailWrapper}>
              <BTypography variant="body1" className={cls.email}>
                {user.emailAddress}
              </BTypography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <NormalButton
              variant={"secondary"}
              color={colors.white}
              hoverColor={colors.veryLightGray}
              labelColor={colors.bildBlue}
              hoverLabelColor={colors.bildBlue}
              borderColor={colors.bildBlue}
              onClick={() => _showMessages([user.id])}
            >
              <i className="far fa-comment-alt" /> &nbsp; message
            </NormalButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.body}>
        <Grid container item md={8} sm={12} xs={12} className={`${cls.sectionWrapper} ${cls.sectionWrapperLeft}`}>
          <Grid item xs={12} className={cls.section}>
            <Grid container item xs={12} justifyContent="flex-end" alignItems="center" className={cls.buttons}>
              <Grid item xs>
                <BTypography variant="h5">Programs</BTypography>
              </Grid>
              <Grid item className={cls.filter}>
                {!thumbnail && (
                  <FilterTextField
                    value={filter}
                    placeholder="Filter"
                    onChange={e => setFilter(e.target.value)}
                    onClear={() => {
                      setFilter("");
                    }}
                    inputPadding={"0.4rem 0.75rem"}
                  />
                )}
              </Grid>
              <Grid item>
                <NormalButton
                  variant={"primary"}
                  color={colors.bildBlue}
                  labelColor={colors.white}
                  onClick={()=>{setCertDialog(!certDialog)}}
                >
                  <i className={"fad fa-file-certificate"} />
                </NormalButton>
              </Grid>
              <Grid item className={cls.button}>
                <NormalButton
                  variant={"primary"}
                  color={colors.bildBlue}
                  labelColor={colors.white}
                  onClick={()=>{setThumbnail(!thumbnail)}}
                >
                  <i className={thumbnail ? "fad fa-th-list" : "fad fa-th-large"} />
                </NormalButton>
              </Grid>
              <Grid item className={cls.button}>
                <NormalButton
                  variant="simple"
                  color={colors.bildBlue}
                  labelColor={colors.white}
                  onClick={() => {
                    setPathDialog(true);
                  }}
                >
                  Assign
                </NormalButton>
              </Grid>
            </Grid>
            <Grid item xs={12} className={cls.sectionInner}>
              {thumbnail && <UserPathThumbnails paths={user.userPaths} allExpanded />}
              {!thumbnail && (
                <UserPathList
                  paths={user.userPaths}
                  filter={filter}
                  perPage={3}
                  shepherds={shepherds}
                  updateShepherd={updateShepherd}
                  removeProgram={removeProgram}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item md={4} sm={12} xs={12} className={`${cls.sectionWrapper}`} justifyContent="center" alignItems="stretch">
          <Grid container item xs={12} direction={"column"} justifyContent="flex-start" alignItems="flex-start" className={cls.section}>
            <Grid item className={cls.sectionHeader}>
              <BTypography variant="h5">Program Activity</BTypography>
            </Grid>
            <Grid container item xs className={cls.sectionInner} justifyContent="center" alignItems="center" >
              <Grid item xs={12}>
                <UserProgramCompletionActivity data={user.userPaths} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item md={4} sm={6} xs={12} className={`${cls.sectionWrapper} ${cls.sectionWrapperLeft}`} justifyContent="center" alignItems="stretch">
          <Grid container item xs={12} direction={"column"} justifyContent="flex-start" alignItems="flex-start" className={cls.section}>
            <Grid item className={cls.sectionHeader}>
              <BTypography variant="h5">Organizations</BTypography>
            </Grid>
            <Grid container item xs className={cls.sectionInner} justifyContent="flex-start" alignItems="flex-start" >
              <UserOrganizations user={user} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item md={4} sm={6} xs={12} className={cls.sectionWrapper} justifyContent="center" alignItems="stretch">
          <Grid container item xs={12} direction={"column"} justifyContent="flex-start" alignItems="flex-start" className={cls.section}>
            <Grid container item className={cls.sectionHeader}>
              <Grid item xs>
                <BTypography variant="h5">Primary Group</BTypography>
              </Grid>
              <Grid item>
                {orgGroups && (
                  <NormalButton
                    dst="manage-organization"
                    organization_id={orgGroups.organizationId}
                    tab="2"
                    variant={"secondary"}
                    borderColor={colors.bildBlue}
                    labelColor={colors.bildBlue}
                    hoverLabelColor={colors.darkBildBlue}
                  >
                    <i className="fas fa-users-class" />
                  </NormalButton>
                )}
              </Grid>
            </Grid>
            <Grid container item xs className={cls.sectionInner} justifyContent="center" alignItems="flex-start">
              {orgGroups && orgGroups.children && orgGroups.children.length > 0 && (
                <Grid item xs={12} className={cls.diagram}>
                  <OrgGroupsDiagram
                    data={orgGroups}
                    defaultScale={1}
                  />
                </Grid>
              )}
              {!orgGroups && (
                <>Not currently a member of a group.</>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item md={4} sm={6} xs={12} className={cls.sectionWrapper} justifyContent="center" alignItems="stretch">
          <Grid container item xs={12} direction={"column"} justifyContent="flex-start" alignItems="flex-start" className={cls.section}>
            <Grid item className={cls.sectionHeader}>
              <BTypography variant="h5">Shepherding</BTypography>
            </Grid>
            <Grid container item xs className={cls.sectionInner} justifyContent="flex-start" alignItems="flex-start" >
              <UserShepherding data={shepherdingData} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item md={4} sm={6} xs={12} className={cls.sectionWrapper} justifyContent="center" alignItems="stretch">
          <Grid container item xs={12} direction={"column"} justifyContent="flex-start" alignItems="flex-start" className={cls.section}>
            <Grid item className={cls.sectionHeader}>
              <BTypography variant="h5">File Activity</BTypography>
            </Grid>
            <Grid container item xs className={cls.sectionInner} justifyContent="flex-start" alignItems="flex-start" >
              <UserFileActivity data={activityData} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AddUserItemDialog
        open={pathDialog}
        closeDialog={() => {
          setPathDialog(false);
        }}
        title={"Add Learning Programs"}
        items={user.availableUserPaths}
        addItems={_addPaths}
        shepherds={shepherds.filter(x => x.id !== user.id)}
      />
      <UserCertificatesDialog
        open={certDialog}
        onClose={()=>{setCertDialog(!certDialog)}}
        user={user}
        certificates={certificates}
      />
      <MessagesDialogLoader
        open={showMessages}
        onClose={() => {
          setShowMessages(false);
        }}
        openMessageUsers={channelUsers}
      />
    </Grid>
  );
}
