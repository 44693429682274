import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { colors, Avatar, PopTip } from "bild-ui";
import BTypography from "../typography/bTypography";

const useStyles = makeStyles({
  avatarWrapper: { position: "relative" },
  avatarName: { paddingLeft: "0.4rem", overflowWrap: "normal" },
  name: props => ({
    fontWeight: props.nameWeight ? props.nameWeight : "normal",
  }),
  badgeWrapper: props => ({
    display: "block",
    position: "absolute",
    bottom: `${props.size && props.size / 2 < 20 ? "-10px" : "-15px"}`,
    right: `${props.size && props.size / 2 < 20 ? "-5px" : "-10px"}`,
  }),
  badgeWrapperLeft: props => ({
    right: "0 !important",
    left: `${props.size && props.size / 2 < 20 ? "-5px" : "-10px"}`,
  }),
  badge: props => ({ width: `${props.size ? props.size / 2 + "px" : "25px"}`, }),
  popBadgeImage: { paddingRight: "0.5rem" },
  popBadge: { width: "50px" }
});

export default function UserAvatar({ size, icon, name, src, srcSize, color, variant, nameVariant, nameWeight, hideName, badgeName, badgeSrc, badgePos, children }) {
  const cls = useStyles({ size, nameWeight });
  nameVariant = nameVariant ? nameVariant : "body1"; // default to "body1" nameVariant

  if (badgeSrc) {
    badgeName = (
      <Grid container>
        <Grid item className={cls.popBadgeImage}><img src={badgeSrc} className={cls.popBadge}/></Grid>
        <Grid item><b><BTypography variant="subtitle1">Beta Tester</BTypography></b><i style={{fontWeight: "200"}}><BTypography variant="body2">Earned Jan 26, 2024</BTypography></i></Grid>
      </Grid>
    );
  }
  return (
    <Grid container justifyContent="flex-start" alignItems="center">
      <Grid item className={cls.avatarWrapper}>
        <Avatar size={size} icon={icon} name={name} src={src} srcSize={srcSize} color={color} variant={variant} children={children} />
        {badgeSrc && (
          <span className={`${cls.badgeWrapper} ${badgePos ? (badgePos === "left" ? cls.badgeWrapperLeft : "") : ""}`}>
            <PopTip text={badgeName}>
              <img src={badgeSrc} className={cls.badge}/>
            </PopTip>
          </span>
        )}
      </Grid>
      {!hideName && (
        <Grid item xs className={cls.avatarName}>
          <BTypography variant={nameVariant} className={cls.name}>{name}</BTypography>
        </Grid>
      )}
    </Grid>
  );
}
