import React from "react";
import { Route } from "react-router-dom";

// LOADERS
import UserPathLoader from "./user-path/userPathLoader.js";
import UserPathItemLoader from "./user-path-item/userPathItemLoader.js";
import LearnerLoader from "./learner/learnerLoader.js";
import ReviewLoader from "./review/reviewLoader.js";
import SharedReviewLoader from "./review/sharedReviewLoader.js";
import SharedPathsLoader from "./review/sharedPathsLoader.js";
import SharedPathLoader from "./review/sharedPathLoader.js";
import ShepherdsReviewLoader from "./review/shepherdsReview/shepherdsReviewLoader.js";
import StaffOrganizationLearnersReviewLoader from "./review/staffOrganizationLearnersReview/staffOrganizationLearnersReviewLoader.js";
import ShepherdingCoachesLoader from "./review/shepherdingCoaches/shepherdingCoachesLoader.js";
import ShepherdingOverviewLoader from "./review/shepherdingOverview/shepherdingOverviewLoader.js";
import ShepherdingTreeLoader from "./review/shepherdingTree/shepherdingTreeLoader.js";

////// EQUIP ROUTES
export default [
  <Route path={`/equip/user_paths/:user_path_id`} element={<UserPathLoader/>} key={"eqp-0"} />,
  <Route path={`/equip/user_path_items/:user_path_item_id`} element={<UserPathItemLoader/>} key={"eqp-1"} />,
  <Route path={`/equip/learner/:user_id`} element={<LearnerLoader/>} key={"eqp-3"} />,
  <Route path={`/equip/learner`} element={<LearnerLoader/>} key={"eqp-4"} />,
  <Route path={`/equip/review/shared`} element={<SharedReviewLoader/>} key={"eqp-5"} />,
  <Route path={`/equip/review`} element={<ReviewLoader/>} key={"eqp-6"} />,
  <Route path={`/equip/review/shared/paths`} element={<SharedPathsLoader/>} key={"eqp-7"} />,
  <Route path={`/equip/review/shared/paths/:path_id/groups/:group_id`} element={<SharedPathLoader/>} key={"eqp-8"} />,
  <Route path={`/equip/review/shepherds`} element={<ShepherdsReviewLoader/>} key={"eqp-9a"} />,
  <Route path={`/equip/review/organizations/:organization_id/learners`} element={<StaffOrganizationLearnersReviewLoader/>} key={"eqp-9b"} />,
  <Route path={`/equip/review/shepherding_coaches`} element={<ShepherdingCoachesLoader/>} key={"eqp-10"} />,
  <Route path={`/equip/review/shepherding_overview`} element={<ShepherdingOverviewLoader/>} key={"eqp-10a"} />,
  <Route path={`/equip/review/users/:user_id/shepherding_overview`} element={<ShepherdingOverviewLoader/>} key={"eqp-10b"} />,
  <Route path={`/equip/review/shepherding_tree`} element={<ShepherdingTreeLoader/>} key={"eqp-11a"} />,
  <Route path={`/equip/review/users/:user_id/shepherding_tree`} element={<ShepherdingTreeLoader/>} key={"eqp-11b"} />,
];
