import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { BTypography, colors, MembersChecklist, NormalButton, PaginationList, PopTip } from "bild-ui";
import List from "./list.js";
import { getSortval, sortItemsInArray, sortMultiTypeArrayVals, uniqueItemsInArray } from "bild-utils/general.js";
import { ClickAwayListener, Grid, Popper } from "@mui/material";

const useStyles = makeStyles({
  header: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: "1",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
  sortHeader: { cursor: "pointer" },
  filterPopper: { minWidth: "25vw", background: colors.white }
});

function SortableList({
  perPage,
  filter,
  filterableCols,
  title,
  details,
  headers,
  sortableColumns,
  hiddenColumns,
  items,
  itemType,
  emptyMessage,
  spacing,
  backgroundColor,
  rowColor,
  evenRowColor,
  itemPadding,
  defaultSortCol,
  defaultSortDir,
  fixedHeight
}) {
  const cls = useStyles();
  const [sortCol, setSortCol] = useState(defaultSortCol ? defaultSortCol : 0); // first header
  const [sortDir, setSortDir] = useState(defaultSortDir ? defaultSortDir : 0); // 2: descending / 0: nothing / 1: ascending
  const [sortableCols, setSortableCols] = useState([]);

  const [localItems, setLocalItems] = useState([]);
  const [filteredCols, setFilteredCols] = useState([]);
  const [filteredColItems, setFilteredColItems] = useState([]);
  const [filterableColValues, setFilterableColValues] = useState([]);

  const [filterColPos, setFilterColPos] = useState(0);
  const [filterColOpen, setFilterColOpen] = useState(false);
  const [filterColAnchorEl, setFilterColAnchorEl] = useState(null);

  useEffect(() => {
    setLocalItems(items);
  }, [items]);

  useEffect(() => {
    if (!filteredCols || filteredCols.length < 1) {
      setFilteredCols(headers.map(x => []));
    }
    if (!filteredColItems || filteredColItems.length < 1) {
      setFilteredColItems(headers.map(x => []));
    }
  }, [headers]);

  useEffect(() => {
    sortList(defaultSortCol ? defaultSortCol : 0); // Sort by first column
    setSortableCols(sortableColumns ? sortableColumns : headers.map((x, i) => i));
  }, [defaultSortCol]);

  useEffect(() => {
    setSortDir(defaultSortDir ? defaultSortDir : 0); // 2: descending / 0: nothing / 1: ascending
  }, [defaultSortDir]);

  // Set filterableColValues
  useEffect(()=>{
    if (items && headers && filterableCols) {
      let allContextItems = headers.map(x => []);

      for (let h = 0; h < filterableCols.length; h++) {
        let col = filterableCols[h];
        let colItems = [];
  
        // Find all values for current column
        for (let i = 0; i < items.length; i++) {
          let item = items[i];
          for (let j = 0; j < item.length; j++) {
            if (j === col) {
              colItems.push(getSortval(item[j]));
            }
          }
        }
  
        // Update State with unique, sorted values for this column
        const newFilteredCol = sortItemsInArray(uniqueItemsInArray(colItems));
        const contextItems = newFilteredCol.map((x,i) => ({id: i, name: x}));
        allContextItems[col] = contextItems;
      }
  
      setFilterableColValues(allContextItems);
    }
  },[items, headers, filterableCols])

  function sortList(col) {
    // figure out what data type the field is and sort accordingly, this only works if a column has the same type of data
    localItems.sort((a, b) => {
      // set default sort values
      let aVal = getSortval(a[col]);
      let bVal = getSortval(b[col]);

      return sortMultiTypeArrayVals(aVal, bVal);
    });

    // Set sort dir and reverse items if needed
    if (col === sortCol) {
      if (sortDir === 1) {
        setSortDir(2);
        localItems.reverse();
      } else {
        setSortDir(1);
      }
    } else {
      setSortDir(1);
    }

    setSortCol(col);
  }

  function _openFilterColPopper(e, col) {
    // Update State with unique, sorted values for this column
    const newFilteredCol = filterableColValues[col].map(x => x.name);
    const newFilteredCols = filteredCols.map((c, i) => {
      if (i === col) {
        return newFilteredCol;
      } else {
        return c;
      }
    });
    setFilteredCols(newFilteredCols);
    setFilterColOpen(true);
    setFilterColPos(col);
    setFilterColAnchorEl(e.currentTarget);
  }

  function _onFilterColItemUpdate(col, item) {
    // Update State with unique, sorted values for this column
    const newFilteredColItems = filteredColItems.map((c, i) => {
      if (i === col) {
        if (c.some(x => x === item)) {
          // Remove the item if it already was in the list
          return c.filter(x => x !== item);
        } else {
          // Add the item if it was not in the list
          return [...c, item];
        }
      } else {
        // We aren't in the right column, skip it
        return c;
      }
    });
    setFilteredColItems(newFilteredColItems);
  }

  if (localItems) {
    // Set Headers
    const listHeadings = [];
    for (let i = 0; i < headers.length; i++) {
      const isSortable = sortableCols.some(x => x === i);
      const isFilterable = filterableCols ? filterableCols.some(x => x === i) : false;
      
      listHeadings.push(
        <PopTip
          text={headers[i] + (headers[i] !== "" && isSortable && (sortCol === i ? (sortDir === 1 ? " ↑" : " ↓") : ""))}
        >
          <BTypography
            variant="h6"
            className={`${cls.header} ${isSortable ? cls.sortHeader: ""}`}
          >
            <span onClick={() => {
              if (isSortable) sortList(i);
            }}>
              {headers[i]}
            </span>
            {headers[i] !== "" && isSortable && (
              <NormalButton variant="micro-tight" onClick={() => { sortList(i); }} labelColor={colors.black}>
                <i className={`fa-fw ${sortCol === i ? (sortDir === 1 ? "fal fa-long-arrow-up" : "fal fa-long-arrow-down") : "fal fa-horizontal-rule"}`} />
              </NormalButton>
            )}
            {headers[i] !== "" && isFilterable && (
              <NormalButton variant="micro-tight" onClick={(e)=>{_openFilterColPopper(e, i)}} labelColor={colors.black}>
                <i className={`${filteredColItems[i] && filteredColItems[i].length > 0 ? "fas" : "fal"} fa-filter`} />
              </NormalButton>
            )}
          </BTypography>
        </PopTip>
      );
    }

    // Set Items
    let listItems = [];
    for (let i = 0; i < localItems.length; i++) {
      let item = localItems[i];
      let listItem = [];
      for (let j = 0; j < item.length; j++) {
        listItem.push(item[j]);
      }
      listItems.push(listItem);
    }

    if (hiddenColumns) {
      // Remove header columns if hiddenColumns passed in
      for (let i = 0; i < listHeadings.length; i++) {
        if (hiddenColumns.some(x => x===i)) {
          delete listHeadings[i];
        }
      }

      // Remove item columns if hiddenColumns passed in
      for (let i = 0; i < listItems.length; i++) {
        for (let j = 0; j < listItems.length; j++) {
          let listItem = listItems[i];
          if (hiddenColumns.some(x => x===j)) {
            delete listItem[j];
          }
        }
      }
    }

    if (filterableCols) {
      // Make a multidimensional array to track all filter values across all filerable columns
      let allMatchingRows = filterableCols.map(x => []);
      
      // Find matchings values across all cols and rows
      for (let i = 0; i < filterableCols.length; i++) {
        const colPos = filterableCols[i];
        const fColItemsPos = filteredColItems[colPos];
        if (fColItemsPos && fColItemsPos.length > 0) {
          for (let j = 0; j < localItems.length; j++) {
            const vals = filterableColValues[colPos].filter((x,q) => fColItemsPos.some(y => y===q));
            // Check this column's row value for a match, if so save to allMatchingRows
            if (vals.some(y => y.name === getSortval(localItems[j][colPos]))) {
              allMatchingRows[i].push(j);
            }
          }
        }
      }

      let rowsToKeep = [];
      // Find matching allMatchingRows values in all columns to save as rowsToKeep
      for (let i = 0; i < allMatchingRows.length; i++) {
        let rtk1 = allMatchingRows[i];
        if (rtk1.length > 0) {
          for (let j = 0; j < rtk1.length; j++) {
            let rtk1_field = rtk1[j];
            let passed = true;
            // We only want to keep rows that have values that match all current filter criteria across filterable columns
            for (let k = 0; k < allMatchingRows.length; k++) {
              let rtk2 = allMatchingRows[k];
              if (rtk2.length > 0 && !rtk2.some(x => x === rtk1_field)) {
                passed = false;
              }
            }
            if (passed) {
              rowsToKeep.push(rtk1_field);
            }
          }
        }
      }

      // Remove every row that was not in the final rowsToKeep
      for (let i = 0; i < filterableCols.length; i++) {
        const colPos = filterableCols[i];
        const fColItemsPos = filteredColItems[colPos];
        if (fColItemsPos && fColItemsPos.length > 0) {
          for (let j = 0; j < localItems.length; j++) {
            if (!rowsToKeep.some(r => r === j)) {
              delete listItems[j];
            }
          }
        }
      }
    }

    const filterColPopper = (
      <Popper open={filterColOpen} anchorEl={filterColAnchorEl} placement="bottom-start" style={{ zIndex: "100" }}>
        <ClickAwayListener onClickAway={()=>{setFilterColOpen(false)}} mouseEvent="onMouseDown">
          <Grid container className={cls.filterPopper}>
            <Grid item xs={12}>
              <MembersChecklist
                filterable={true}
                members={filterableColValues[filterColPos]}
                selectedMembers={filteredColItems[filterColPos]}
                updateMember={(d)=>{_onFilterColItemUpdate(filterColPos, d)}}
                hideAvatar
                variant={"tall"}
                wrapperPadding={"0.5rem 0"}
                itemPadding={"0 0.5rem"}
              />
            </Grid>
          </Grid>
        </ClickAwayListener>
      </Popper>
    );
    
    // Remove any "removed", empty listItems row
    listItems = listItems.filter(n => n);
    

    if (perPage) {
      return (
        <>
          <PaginationList
            perPage={perPage}
            filter={filter}
            title={title}
            details={details}
            headings={listHeadings}
            items={listItems}
            itemType={itemType}
            emptyMessage={emptyMessage}
            spacing={spacing}
            backgroundColor={backgroundColor}
            rowColor={rowColor}
            evenRowColor={evenRowColor}
            itemPadding={itemPadding}
            fixedHeight={fixedHeight}
          />
          {filterColPopper}
        </>
      );
    } else {
      return (
        <>
          <List
            filter={filter}
            title={title}
            details={details}
            headings={listHeadings}
            items={listItems}
            itemType={itemType}
            emptyMessage={emptyMessage}
            spacing={spacing}
            backgroundColor={backgroundColor}
            rowColor={rowColor}
            evenRowColor={evenRowColor}
            itemPadding={itemPadding}
            fixedHeight={fixedHeight}
          />
          {filterColPopper}
        </>
      );
    }
  } else {
    return null;
  }
}

export default SortableList;
